import { Contract } from "ethers";
import Erc20Abi from "../abi/Erc20.abi.json";
import { Ethereum, ConnectionStore } from "@/crypto/helpers";

class SmartContract {
  _address = null;
  _type = null;

  //  ethers contract instance
  _instance: null | Contract = null;
  _provider: any = null;

  metaData = {
    address: null,
    name: null,
    symbol: null,
    tokens: [],
    balance: 0
  };

  /*
   * @param options: object, address = string in hex, type = 'common' || 'bundle' || 'allowList'
   * */
  constructor({ address, type = "common" }: any) {
    this._address = address;
    this._type = type;
    this.metaData.address = address;
  }

  async fetchMetadata() {
    const Contract = await this._getInstance();
    if (!Contract) return null;

    try {
      this.metaData.name = await Contract.name();
      this.metaData.symbol = (await Contract.symbol()) || "";
    } catch (e) {
      console.log(
        "[SmartContract] Error get contract meta from contract " +
          this._address,
        e
      );
    }
  }

  async _getInstance(): Promise<Contract | null> {
    if (!this._instance) {
      this._instance = await new Promise(async (resolve) => {
        let abi = Erc20Abi;
        if (this._address == null) {
          this._address = Ethereum.getSettings(
            ConnectionStore.getNetwork().name
          ).tokenAddress;
        }
        if (!this._address) return resolve(null);

        const contract = new Contract(this._address, abi, this._getProvider());
        return contract;
      });
    }

    return this._instance;
  }

  _getProvider() {
    if (!this._provider) this._provider = ConnectionStore.getProvider();
    return this._provider;
  }
}

export default SmartContract;
