import { ethers } from "ethers";
import { useEvmStore } from "@/hooks/useStores";

enum ENetworks {
  goerli = "goerli",
  mainnet = "mainnet",
  binance = "binance"
}

const networks: Record<ENetworks, any> = {
  [ENetworks.mainnet]: {
    name: "mainnet",
    chainId: 1,
    transactionExplorer: "https://etherscan.io/tx/",
    accountExplorer: "https://etherscan.io/address/",
    gasLimit: 400000
  },
  [ENetworks.goerli]: {
    name: "goerli",
    chainId: 5,
    transactionExplorer: "https://goerli.etherscan.io/tx/",
    accountExplorer: "https://goerli.etherscan.io/address/",
    blockExplorer: "https://goerli.etherscan.io/tx/",
    gasLimit: 700000
  },
  [ENetworks.binance]: {
    name: "binance",
    chainId: 56,
    transactionExplorer: "https://bscscan.com/tx/",
    accountExplorer: "https://bscscan.com/address/",
    blockExplorer: "https://bscscan.com/tx/",
    gasLimit: 700000
  }
};

const settings: Record<ENetworks, any> = {
  [ENetworks.mainnet]: {
    api: "https://api.rarible.org/v0.1",
    chainlinkRpc: "https://rpc.ankr.com/eth",
    blockchain: "ETHEREUM"
  },
  [ENetworks.goerli]: {
    api: "https://api.rarible.org/v0.1",
    chainlinkRpc: "https://rpc.ankr.com/eth",
    blockchain: "ETHEREUM"
  },
  [ENetworks.binance]: {
    api: "https://api.rarible.org/v0.1",
    chainlinkRpc: "https://rpc.ankr.com/eth",
    blockchain: "BINANCE"
  }
};

export function getProvider(provider: any) {
  return new ethers.providers.Web3Provider(provider, "any");
}

export const ConnectorTypes = {
  RARIBLE: "rarible"
};

export const AppStorage: any = {
  _store: null,

  getStore() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    if (!this._store) this._store = useEvmStore();
    return this._store;
  }
};

export function getNameByChainID(chainID: number) {
  const [name] = Object.entries(networks).find(
    ([, data]) => data?.chainId === chainID
  ) || ["unknown"];
  const id = `NEXT_PUBLIC_NETWORK_${name.toUpperCase()}_SUPPORT`;
  if (!id) return "unknown";
  let isSupport = name !== "unknown" ? !!+process.env[id]! : false;

  return isSupport ? name : "unknown";
}

export function getData(networkName: ENetworks) {
  return networkName ? (networks as any)[networkName.toLowerCase()] : null;
}

export function getSettings(networkName: ENetworks) {
  return networkName ? (settings as any)[networkName.toLowerCase()] : null;
}
