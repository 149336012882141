import { makeAutoObservable } from "mobx";

class AppStore {
  constructor() {
    makeAutoObservable(this);
  }
  isAppReady = false;
  isCollectionsLoading = false;
  isWalletConnectModalOpen = false;
  walletConnectCode = "";
  walletConnectCloseHandler = null;
  networkId = 0;

  connection = {
    userIdentity: null,
    userNetworkName: null,
    userNetworkSupported: false
  };

  processStatus = {
    code: "",
    addition: []
  };

  openWalletConnectQR = (copyCode: any, closeHandler: any) => {
    this.walletConnectCode = copyCode;
    this.walletConnectCloseHandler = closeHandler;
    this.isWalletConnectModalOpen = true;
  };
  closeWalletConnectQR = ({ isAutomatic = false } = {}) => {
    if (!isAutomatic && this.walletConnectCloseHandler) {
      // this.walletConnectCloseHandler();
    }
    this.isWalletConnectModalOpen = false;
    this.walletConnectCloseHandler = null;
  };

  setAppReady = () => {
    this.isAppReady = true;
  };

  setUserIdentity = (value = null) => {
    this.connection.userIdentity = value;
  };
  setUserNetworkId = (value: number) => {
    this.networkId = value;
  };
}

export default AppStore;
