export const cutString = (str: string, takeFirst: number, takeLast: number) => {
  if (!str || str.length <= takeFirst + takeLast) {
    return str;
  }

  return (
    str.substring(0, takeFirst) +
    "..." +
    str.substring(str.length - takeLast, str.length)
  );
};

export const capitalizeString = (str: string) => {
  if (!str || str.length === 0) {
    return "";
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const stringCompare = (str1 = "", str2 = "") => {
  return !str1.localeCompare(str2, undefined, { sensitivity: "accent" });
};

export const randomString = (): string => {
  return (+new Date() * Math.random()).toString(36).substring(0, 6);
};

export const generateRandomStringForSeed = (length: number) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters[randomIndex];
  }
  return result;
};

export const generateRandomNumber = () => {
  return Math.floor(Math.random() * 11);
};
