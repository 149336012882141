import { useCallback, useEffect, useMemo, useState } from "react";
import { Card, Layout } from "antd";
import { observer } from "mobx-react-lite";
import { Typography, Table } from "antd";
import { cutString } from "@/utils/strings";
import { useUserStore } from "@/providers/BaseStoresProvider";
import type { TableColumnsType } from "antd";

const { Title, Text } = Typography;

export const LotteryPage = observer((): JSX.Element => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const userStore = useUserStore();

  const loadRequests = useCallback(async () => {
    setLoading(true);
    await userStore.loadLotteries();
    setLoading(false);
  }, []);

  useEffect(() => {
    loadRequests();
  }, [loadRequests]);

  const columns: TableColumnsType<any> = useMemo(
    () => [
      {
        title: "DB ID",
        dataIndex: "_id",
        key: "_id",
        render: (val: string) => (
          <Text
            copyable={{
              text: val ?? ""
            }}
          >
            {cutString(val, 4, 5)}
          </Text>
        )
      },
      {
        title: "Lottery ID",
        dataIndex: "lotteryId",
        key: "lotteryId",
        defaultSortOrder: "descend",
        sorter: (a: any, b: any) => a.lotteryId - b.lotteryId
      },
      {
        title: "Tickets bought",
        dataIndex: "allTickets",
        key: "allTickets",
        defaultSortOrder: "descend",
        sorter: (a: any, b: any) => a.allTickets - b.allTickets
      },
      {
        title: "WIN numbers",
        dataIndex: "result",
        key: "result",
        render: (val: number[], full: any) => (
          <Text>
            {val.map((_, key) =>
              key !== val.length - 1 ? _ + " ," : <b>{_}</b>
            )}
          </Text>
        )
      }
    ],
    [userStore]
  );

  return (
    <Layout style={{ padding: "24px" }}>
      <Layout.Content
        style={{
          padding: 24,
          margin: 0,
          minHeight: 280
        }}
      >
        <Typography>
          <Title>{"Lotteries (last 100)"}</Title>
        </Typography>
        <Table
          className="table-min"
          dataSource={userStore.lotteriesList}
          columns={columns}
          loading={isLoading}
          scroll={{ x: true }}
          rowKey={(record) => record._id}
        />
      </Layout.Content>
    </Layout>
  );
});
