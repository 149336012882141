import { Ethereum, ConnectionStore } from "@/crypto/helpers";
import SmartContract from "@/crypto/EVM/SmartContract";

class EVM {
  connector: any;

  async init() {
    return await this.connector.init(this);
  }
  async connectToWallet(value: string, chainId: number) {
    console.log("connectToWallet");
    return await this.connector.connectToWallet(value, chainId);
  }
  async checkInchInjected() {
    return await this.connector.checkInchInjected();
  }
  async disconnect() {
    return await this.connector.disconnect();
  }
  async isUserConnected() {
    return await this.connector.isUserConnected();
  }

  async getContractProvider() {
    const { fetchAmount } = Ethereum.getData(ConnectionStore.getNetwork().name);

    const Contract = new SmartContract({
      address: fetchAmount
    });

    return Contract._getProvider();
  }

  tryToConnectToUnsupportedNetwork() {
    console.log("network not supported");
    alert("Sorry, we did not support this network");
  }
}

export default EVM;
