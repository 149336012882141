import { observer, useLocalObservable } from "mobx-react-lite";
import { createContext, useContext, useMemo, useEffect, useState } from "react";
import { UserStore } from "@/stores/UserStore";
import { notification } from "antd";
import type { NotificationInstance } from "antd/es/notification/interface";

export const ContextNotify = createContext({ name: "Default" });

type BaseStores = {
  apiNotify: NotificationInstance;
  userStore: UserStore;
};

const baseStoreContext = createContext<BaseStores>({} as BaseStores);

export const ProvideBaseStores = observer(({ children }: any) => {
  const userStore = useLocalObservable(() => new UserStore());

  const [apiNotify, contextHolder] = notification.useNotification();

  const contextValue = useMemo(() => ({ name: "Ant" }), []);

  const stores = useMemo(
    () => ({
      userStore,
      apiNotify
    }),
    [userStore, apiNotify]
  );

  useEffect(() => {
    userStore.checkProtected();
  }, []);

  return (
    <baseStoreContext.Provider value={stores}>
      <ContextNotify.Provider value={contextValue}>
        {contextHolder}
        {children}
      </ContextNotify.Provider>
    </baseStoreContext.Provider>
  );
});

export const useUserStore = () => {
  const { userStore } = useContext(baseStoreContext);

  return userStore;
};

export const useAntdNotify = () => {
  const { apiNotify } = useContext(baseStoreContext);

  return apiNotify;
};
