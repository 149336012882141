import { useCallback, useEffect, useMemo, useState } from "react";
import { Layout } from "antd";
import { observer } from "mobx-react-lite";
import { Typography, Table, Button, Row } from "antd";
import { useUserStore } from "@/providers/BaseStoresProvider";
import { unixTsToDateStr } from "@/utils/dates";
import BN from "@/utils/BN";

const { Title, Text } = Typography;

export const BanksPage = observer((): JSX.Element => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const userStore = useUserStore();

  const loadRequests = useCallback(async () => {
    setLoading(true);
    await userStore.loadBanksGames();
    setLoading(false);
  }, []);

  useEffect(() => {
    loadRequests();
  }, [loadRequests]);

  const columns: any = useMemo(
    () => [
      {
        title: "ID",
        dataIndex: "id",
        key: "id"
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        render: (val: any, full: any) =>
          `${new BN(val).toFixed(5)} ${full?.symbol} ~${new BN(val)
            .times(userStore.tokenPrice[full?.symbol ?? ""] ?? 0)
            .toFixed(0)}$`
      },
      {
        title: "Symbol",
        dataIndex: "symbol",
        key: "symbol"
      },
      {
        title: "Game name",
        dataIndex: "name",
        key: "name",
        defaultSortOrder: "descend",
        sorter: (a: any, b: any) => a?.name?.length - b?.name?.length
      },
      {
        title: "Action",
        key: "action",
        render: (record: any) => (
          <Button
            onClick={async () => {
              const isCrash = record?.bankName === "crash";
              await userStore.resetBanks(
                record?.symbol,
                record?.id,
                false,
                isCrash
              );
              loadRequests();
            }}
            type="primary"
            size="middle"
          >
            Reset
          </Button>
        )
      }
    ],
    [userStore]
  );

  return (
    <Layout style={{ padding: "24px" }}>
      <Layout.Content
        style={{
          padding: 24,
          margin: 0,
          minHeight: 280
        }}
      >
        <div className="row">
          <Title>Banks list</Title>
          <Button
            type="primary"
            onClick={async () => {
              await userStore.resetBanks("", "", true, false);
              loadRequests();
            }}
          >
            RESET all
          </Button>
        </div>
        <Table
          className="table-min"
          dataSource={userStore.banksList}
          columns={columns}
          loading={isLoading}
          scroll={{ x: true }}
          rowKey={(record) => record.txNonce}
        />
      </Layout.Content>
    </Layout>
  );
});
