import { useMemo, useState } from "react";
import { Button, Card } from "antd";
import { observer } from "mobx-react-lite";
import { Typography, Table, Row, Col } from "antd";
import { cutString } from "@/utils/strings";
import { useUserStore } from "@/providers/BaseStoresProvider";
import BN from "@/utils/BN";
import { Link } from "react-router-dom";
import { unixTsToDateStr } from "@/utils/dates";

const { Text } = Typography;

// const ADMIN_FEE = 0.3;
// const REV_SHARE = 0.4;

export const UsersTable = observer(
  ({ isLoading }: { isLoading: boolean }): JSX.Element => {
    const userStore = useUserStore();
    const [rowsLoading, setRowLoading] = useState<string[]>([]);

    const columns: any = useMemo(
      () => [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
          render: (val: string) => (
            <Text
              copyable={{
                text: val ?? ""
              }}
            >
              {cutString(val, 4, 5)}
            </Text>
          )
        },
        {
          title: "Email / name",
          dataIndex: "email/name",
          key: "name",
          render: (val: string, full: any) =>
            `${full?.email ? full?.email : "-"} / ${full?.name}`
        },
        {
          title: "Created",
          dataIndex: "createdAtUnix",
          key: "createdAtUnix",
          render: (val: number) => unixTsToDateStr(val, "DD.MM.YYYY HH:mm:ss"),
          defaultSortOrder: "descend",
          sorter: (a: any, b: any) => a.createdAtUnix - b.createdAtUnix
        },
        {
          title: "Wallet",
          dataIndex: "walletAddress",
          key: "walletAddress",
          render: (val: string) =>
            val ? (
              <Text
                copyable={{
                  text: val ?? ""
                }}
              >
                {cutString(val, 4, 5)}
              </Text>
            ) : null
        },
        {
          title: "Locked",
          dataIndex: "lockedBonus",
          key: "lockedBonus",
          render: (val: string) => Number(val).toFixed(2) + " $"
        },
        {
          title: "Claimed",
          dataIndex: "bonusesHistory",
          key: "bonusesHistory",
          render: (val: any[]) => {
            const total = val.reduce((acc, curr) => {
              return curr?.type === "claim" ? acc.plus(curr.amount) : acc;
            }, new BN(0));

            return total.toFixed(2) + " $";
          },
          sorter: (a: any, b: any) => {
            const totalA = a.bonusesHistory.reduce((acc: any, curr: any) => {
              return curr?.type === "claim" ? acc.plus(curr.amount) : acc;
            }, new BN(0));
            const totalB = b.bonusesHistory.reduce((acc: any, curr: any) => {
              return curr?.type === "claim" ? acc.plus(curr.amount) : acc;
            }, new BN(0));

            return totalA.minus(totalB);
          }
        },
        {
          title: "Revenue",
          dataIndex: "rev",
          key: "rev",
          render: (val: any[], full: any) => {
            return (
              new BN(full.totalDeposits).minus(full.totalWithdraw).toFixed(2) +
              " $"
            );
          },
          sorter: (a: any, b: any) => {
            const totalA = new BN(a.totalDeposits).minus(a.totalWithdraw);
            const totalB = new BN(b.totalDeposits).minus(b.totalWithdraw);

            return totalA.minus(totalB);
          }
        },
        {
          title: "Deposits",
          dataIndex: "totalDeposits",
          key: "totalDeposits",
          render: (val: any[]) => {
            return val + " $";
          },
          sorter: (a: any, b: any) =>
            Number(a.totalDeposits) - Number(b.totalDeposits)
        },
        {
          title: "Withdraw",
          dataIndex: "totalWithdraw",
          key: "totalWithdraw",
          render: (val: any[]) => {
            return val + " $";
          },
          sorter: (a: any, b: any) =>
            Number(a.totalWithdraw) - Number(b.totalWithdraw)
        },
        {
          title: "Wagered",
          dataIndex: "wageredForBonus",
          key: "wageredForBonus",
          render: (val: string) =>
            new BN(val).times(0.01).times(0.25).toFixed(4) + " $",
          defaultSortOrder: "descend",
          sorter: (a: any, b: any) =>
            Number(a.wageredForBonus) - Number(b.wageredForBonus)
        },
        // {
        //   title: "Relogin",
        //   dataIndex: "Relogin",
        //   key: "Relogin",
        //   render: (val: any, data: any) => (
        //     <Button onClick={() => userStore.reloginUser(data.id)}>
        //       Relogin
        //     </Button>
        //   )
        // },
        {
          title: "Check",
          dataIndex: "check",
          key: "check",
          render: (val: any, data: any) => {
            return (
              <Link to={`/user/${data.id}`}>
                <Button type="primary">Check</Button>
              </Link>
            );
          }
        }
      ],
      [userStore]
    );

    return (
      <Table
        className="table-min"
        dataSource={userStore.usersList}
        columns={columns}
        scroll={{ x: true }}
        loading={isLoading}
        rowKey={(record) => record.id}
        expandable={{
          onExpand: async (isOpen, record) => {
            if (isOpen) {
              setRowLoading((s) => [...s, record.id.toString() ?? ""]);
              await userStore.loadUserWallets(record.id);
              await userStore.loadUserLoginDatas(record.id);

              setRowLoading((s) => {
                const index = s.indexOf(record.id.toString() ?? "");
                s.splice(index, 1);
                return [...s];
              });
            }
          },
          expandedRowRender: (record) => {
            const wallets = userStore.loadedUsersWallets.find((_) => {
              const key = Object.keys(_);
              if (key[0] === record.id) return _;
            });

            const loginDatas = userStore.loadedLoginsData.find((_) => {
              const key = Object.keys(_);
              if (key[0] === record.id) return _;
            });

            console.log(loginDatas, "---uloginDatas");

            if (!wallets) return <div>No wallets</div>;

            if (rowsLoading.includes(record.id)) {
              return (
                <Card
                  style={{ maxWidth: "40%", margin: "0 auto" }}
                  type="inner"
                  bordered
                  title={`Wallets for ${record.id}`}
                >
                  Loading...
                </Card>
              );
            }

            return (
              <div className="row-table">
                <Card
                  style={{ maxWidth: "40%", margin: "0 auto" }}
                  type="inner"
                  bordered
                  title={`Wallets for ${record.id}`}
                >
                  {Object.values(wallets)[0].map((_, i) => (
                    <Row justify="space-between" key={_.id}>
                      <Text color="grey">{_.alias}:</Text>{" "}
                      <Text>{_.amount}</Text>
                    </Row>
                  ))}
                </Card>
                <Card
                  style={{ maxWidth: "40%", margin: "0 auto" }}
                  type="inner"
                  bordered
                  title={`LOGIN history for ${record.id}`}
                >
                  {loginDatas &&
                    Object.values(loginDatas)[0].map((_, i) => (
                      <Col className="card-ip">
                        <Row
                          style={{ marginBottom: "10px" }}
                          justify="space-between"
                          key={i}
                        >
                          <Text color="grey">Device</Text>{" "}
                          <Text>{_.deviceFull}</Text>
                        </Row>
                        <Row
                          style={{ marginBottom: "10px" }}
                          justify="space-between"
                          key={i}
                        >
                          <Text color="grey">IP</Text> <Text>{_.ipFull}</Text>
                        </Row>
                        <Row
                          style={{ marginBottom: "10px" }}
                          justify="space-between"
                          key={i}
                        >
                          <Text color="grey">Time</Text>{" "}
                          <Text>
                            {unixTsToDateStr(
                              new BN(_.loginTime ?? 0).div(1000).toNumber(),
                              "DD.MM.YYYY HH:mm:ss"
                            )}
                          </Text>
                        </Row>
                      </Col>
                    ))}
                </Card>
              </div>
            );
          },
          rowExpandable: (record) => record.id !== null
        }}
      />
    );
  }
);
