import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { Layout, Button, Row, Typography, Image } from "antd";
import { useUserStore } from "@/providers/BaseStoresProvider";
import Logo from "@/assets/img/logo.png";

const headerStyle: React.CSSProperties = {
  width: "100%",
  padding: "0px 20px"
};

const { Title } = Typography;

export const Header = observer(() => {
  const navigate = useNavigate();
  const userStore = useUserStore();

  const handleLogout = async () => {
    try {
      await userStore.fullLogout();
      userStore.checkProtected();
    } catch (err) {
      console.log(err, "ERR");
    }
  };

  return (
    <Layout.Header style={headerStyle}>
      <Row style={{ height: "100%" }} justify="space-between" align="middle">
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={() => navigate("/")}
        >
          <Image src={Logo} style={{ width: "40px" }} />
          <Title
            style={{ color: "#fff", fontWeight: 700, margin: "0 0 0 12px" }}
            level={2}
          >
            Bitruby
          </Title>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {userStore.connected ? (
            <Button onClick={() => handleLogout()}>Disconnect</Button>
          ) : (
            <Button onClick={() => userStore.handleClickMetamask()}>
              Connect
            </Button>
          )}
        </div>
      </Row>
    </Layout.Header>
  );
});
