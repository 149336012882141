import { useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { Typography, Table, Row } from "antd";
import { cutString } from "@/utils/strings";
import { useUserStore } from "@/providers/BaseStoresProvider";
import { IoCheckboxSharp, IoCloseCircle } from "react-icons/io5";
import { unixTsToDateStr } from "@/utils/dates";

const { Text } = Typography;

interface IProps {
  isLoading: boolean;
  gamesList: any[];
}

export const PlinkoTable = observer(
  ({ isLoading, gamesList }: IProps): JSX.Element => {
    const userStore = useUserStore();
    const columns = useMemo(
      () => [
        {
          title: "ID",
          dataIndex: "_id",
          key: "_id",
          render: (val: string) => (
            <Text
              copyable={{
                text: val ?? ""
              }}
            >
              {cutString(val, 4, 5)}
            </Text>
          )
        },
        {
          title: "Time",
          dataIndex: "bet_time",
          key: "bet_time",
          render: (val: number) => unixTsToDateStr(val, "DD.MM.YYYY HH:mm")
        },
        {
          title: "Lines",
          dataIndex: "lines",
          key: "lines"
        },
        {
          title: "Risk",
          dataIndex: "risk",
          key: "risk"
        },
        {
          title: "Amount",
          dataIndex: "amount",
          key: "amount",
          render: (val: string, full: any) => `${val} ${full.symbol}`
        },
        {
          title: "Game result",
          dataIndex: "payout",
          key: "payout"
        },
        {
          title: "User profit",
          dataIndex: "profit",
          key: "profit",
          render: (val: string, full: any) => `${val} ${full.symbol}`
        },
        {
          title: "Win/Lose",
          dataIndex: "winStatus",
          key: "winStatus",
          className: "vertical-middle",
          render: (val: boolean, full: any) => (
            <>
              {!full.profit.includes("-") ? (
                <IoCheckboxSharp color="green" size={30} />
              ) : (
                <IoCloseCircle color="red" size={30} />
              )}
            </>
          )
        }
      ],
      [userStore]
    );

    return (
      <Table
        className="table-min"
        dataSource={gamesList}
        columns={columns}
        scroll={{ x: true }}
        loading={isLoading}
        rowKey={(record) => record._id}
      />
    );
  }
);
