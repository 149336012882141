import dayjs from "dayjs";

export interface ITime {
  total: number;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

export const getTimeRemaining = (endtime: number): ITime => {
  let t = endtime - new Date().getTime();
  let seconds = Math.floor((t / 1000) % 60);
  let minutes = Math.floor((t / 1000 / 60) % 60);
  let hours = Math.floor((t / (1000 * 60 * 60)) % 24);
  let days = Math.floor(t / (1000 * 60 * 60 * 24));
  return {
    total: t,
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds
  };
};

export const unixTsToDateStr = (timestamp: number, format = "DD.MM.YYYY") => {
  return dayjs.unix(timestamp).format(format);
};
