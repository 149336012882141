/*
 * Storage for selected in rarible provider like:
 * 'injected' - for MetaMask
 * 'walletConnect' - for walletConnect
 * */

const storeName = "saved_provider";

export default {
  getValue() {
    return "rarible" || undefined;
  },
  setValue(value: string) {
    if (typeof window === "undefined") return;
    localStorage.setItem(storeName, value || "");
  }
};
