import { useMemo } from "react";
import { observer } from "mobx-react-lite";
import { Typography, Table } from "antd";
import { cutString } from "@/utils/strings";
import { useUserStore } from "@/providers/BaseStoresProvider";
import type { TableColumnsType } from "antd";
import BigNumber from "bignumber.js";

const { Text } = Typography;

interface IProps {
  isLoading: boolean;
  gamesList: any[];
}

// 0 - no numbers + 1 ticket
// 1 - 3 numbers - 1$
// 2 - 4 numbers - 20$
// 3 - 5 numbers - 1000$
// 4 - 6 numbers - 10000$
// 5 - no prize - 0$ + 0 ticket

const lotteryPrizes = [
  {
    type: 0,
    prize: "+1 ticket",
    usdt: 0
  },
  {
    type: 1,
    prize: "+1$",
    usdt: 1
  },
  {
    type: 2,
    prize: "+20$",
    usdt: 20
  },
  {
    type: 3,
    prize: "+1000$",
    usdt: 1000
  },
  {
    type: 4,
    prize: "+10000$",
    usdt: 10000
  },
  {
    type: 5,
    prize: "no prize",
    usdt: 0
  }
];

export const LotteryTable = observer(
  ({ isLoading, gamesList }: IProps): JSX.Element => {
    const userStore = useUserStore();

    const totalResult = (tickets: any) => {
      const totalUsdt: BigNumber = tickets.reduce(
        (acc: BigNumber, curr: any) => {
          const reward = lotteryPrizes.find(
            (_) => _.type === curr.rewardType
          )?.usdt;

          const ticketMult = reward
            ? new BigNumber(reward).times(curr.ticketsBought)
            : 0;

          return acc.plus(ticketMult);
        },
        new BigNumber(0)
      );

      const freeTickets: BigNumber = tickets.reduce(
        (acc: BigNumber, curr: any) => {
          const rewardNum = curr.rewardType === 0 ? true : false;

          const ticketMult = rewardNum
            ? new BigNumber(1).times(curr.ticketsBought)
            : 0;

          return acc.plus(ticketMult);
        },
        new BigNumber(0)
      );

      return {
        totalUsdt: totalUsdt.toFixed(2),
        freeTickets: freeTickets.toString()
      };
    };

    const columns = useMemo(
      () => [
        {
          title: "DB id",
          dataIndex: "_id",
          key: "_id",
          render: (val: string) => (
            <Text
              copyable={{
                text: val ?? ""
              }}
            >
              {cutString(val, 4, 5)}
            </Text>
          )
        },
        {
          title: "Lottery ID",
          dataIndex: "lotteryId",
          key: "lotteryId"
        },
        {
          title: "Total WON",
          dataIndex: "totalUsdt",
          key: "totalUsdt",
          render: (val: any, full: any) => {
            const totalData = totalResult(full.tickets);

            return `+${totalData.totalUsdt} USDT`;
          }
        },
        {
          title: "Total SPENT",
          dataIndex: "tickets",
          key: "tickets",
          render: (val: any[]) => {
            return `${new BigNumber(val.length).times(0.1).toString()} USDT`;
          }
        },
        {
          title: "FREE Tickets WON",
          dataIndex: "freeTickets",
          key: "freeTickets",
          render: (val: number[], full: any) => {
            const totalData = totalResult(full.tickets);

            return `+${totalData.freeTickets} tickets`;
          }
        },
        {
          title: "Tickets bought",
          dataIndex: "tickets",
          key: "tickets",
          render: (val: any[]) => val.length
        }
      ],
      [userStore]
    );

    const columnsTickets: TableColumnsType<any> = useMemo(
      () => [
        {
          title: "ID",
          dataIndex: "_id",
          key: "_id",
          render: (val: string) => (
            <Text
              copyable={{
                text: val ?? ""
              }}
            >
              {cutString(val, 4, 5)}
            </Text>
          )
        },
        {
          title: "Reward",
          dataIndex: "rewardType",
          key: "rewardType",
          render: (val: number) => (
            <Text>
              {`${val} (${lotteryPrizes.find((_) => _.type === val)?.prize})`}
            </Text>
          )
        },
        {
          title: "Reward Type",
          dataIndex: "rewardType",
          key: "rewardType",
          defaultSortOrder: "descend",
          sorter: (a: any, b: any) => a.rewardType - b.rewardType
        },
        {
          title: "Tickets bought",
          dataIndex: "ticketsBought",
          key: "ticketsBought"
        },
        {
          title: "User nums",
          dataIndex: "numbers",
          key: "numbers",
          render: (val: number[], full: any) => (
            <Text>
              {val.map((_) => _ + " ,")} <b>{full.jackpotNum}</b>
            </Text>
          )
        },
        {
          title: "Test coins",
          dataIndex: "testCoins",
          key: "testCoins",
          render: (val: boolean) => (val ? "Yes" : "No")
        }
      ],
      [userStore]
    );

    return (
      <Table
        className="table-min"
        dataSource={gamesList}
        columns={columns}
        scroll={{ x: true }}
        loading={isLoading}
        rowKey={(record) => record._id}
        expandable={{
          expandedRowRender: (record) => {
            return (
              <>
                <Table
                  className="table-min table-main"
                  dataSource={record.tickets}
                  columns={columnsTickets}
                  scroll={{ x: true }}
                  loading={isLoading}
                  pagination={false}
                  rowKey={() => record._id}
                />
              </>
            );
          },
          rowExpandable: (record) => record._id !== null
        }}
      />
    );
  }
);
