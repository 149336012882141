const ROUTES = {
  index: { path: "/" },
  manage: { path: "/manage" },
  referrals: { path: "/referrals" },
  banks: { path: "/banks" },
  lottery: { path: "/lottery" },
  user: { path: "/user/:id" }
};

export default ROUTES;
